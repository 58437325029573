import React, { Fragment } from "react";
// import {
//   FaFacebookSquare,
//   FaInstagram,
//   FaRedditSquare,
//   FaTwitter
// } from "react-icons/fa";

const Footer = () => {
  return (
    <Fragment>
      <footer className='page-footer black'>
        <div>
          <p
            style={{
              fontSize: ".75em",
              textAlign: "center",
              paddingBottom: "1%"
            }}
          >
            © All content and images are the property of MegaMemeCorp 2020
          </p>
        </div>
        {/* <div className='container'>
          <div className='row'>
            <div className='col l6 s12'>
              <h5 className='white-text'>Footer Content</h5>
              <p className='grey-text text-lighten-4'>Yeet</p>
            </div>
            <div className='col l4 offset-l2 s12'>
              <h5 className='white-text'>We're hip and with it</h5>
              <ul style={{ fontSize: "2em" }}>
                <li>
                  <FaInstagram />
                </li>
                <li>
                  <FaTwitter />
                </li>
                <li>
                  <FaFacebookSquare />
                </li>

                <li>
                  <FaRedditSquare />
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className='footer-copyright'>
          <div className='container'>
            © 2014 Copyright Text
            <a className='grey-text text-lighten-4 right' href='#!'>
              More Links
            </a>
          </div>
        </div> */}
      </footer>
    </Fragment>
  );
};

export default Footer;
