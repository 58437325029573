import React from "react";
import "materialize-css/dist/css/materialize.min.css";

const Featured = () => {
  return (
    <div
      className='container'
      style={{
        width: "auto",
        maxWidth: "512px",
        paddingTop: "3em",
        height: "80vh"
      }}
    >
      <div
        className='col s12 m6 center responsive-img'
        style={{
          display: "grid",
          alignContent: "center",
          border: "1px solid black",
          borderTop: "3px solid black",
          paddingBottom: "3%"
        }}
      >
        <h1
          style={{
            fontSize: "2em",
            background: "black",
            color: "white",
            margin: "0%"
          }}
        >
          Featured Meme
        </h1>
        <img
          className='responsive-img '
          alt=''
          src='https://memes.megamemecorp.com/latest.png'
          style={{
            background: "gray",
            width: "auto"
          }}
        />
        <div
          style={{
            color: "white"
          }}
        ></div>
      </div>
    </div>
  );
};
export default Featured;
