import React, { Fragment } from "react";
import Navbar from "./layout/Navbar";
import Featured from "./layout/Featured";
// import Careers from "./pages/Careers";
// import Archive from "./layout/Archive";
import Footer from "./layout/Footer";
import "./App.css";

function App() {
  return (
    <Fragment>
      <Navbar />
      <Featured />
      {/* <Archive /> */}
      {/* <Careers /> */}
      <Footer />
    </Fragment>
  );
}

export default App;
