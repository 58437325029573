import React, { Fragment } from "react";
import { FaCog } from "react-icons/fa";
//import css?
import M from "materialize-css";
import "materialize-css/dist/css/materialize.min.css";

const Navbar = () => {
  document.addEventListener("DOMContentLoaded", function() {
    var elems = document.querySelectorAll(".sidenav");
    // var instances =
    M.Sidenav.init(elems);
  });

  return (
    <Fragment>
      <nav className=' black' style={{ width: "100vw" }}>
        <div className='nav-wrapper '>
          <a href='#!' className='brand-logo center hide-on-med-and-down'>
            <h3
              style={{
                marginTop: "-.25%",
                lineHeight: "1.5em",
                fontSize: "1.5em"
              }}
            >
              Mega Meme C
              <FaCog
                className='fa-spin'
                style={{ height: ".75em", width: ".75em" }}
              />
              rp
            </h3>
          </a>

          <FaCog
            className=' brand-logo  fa-spin hide-on-med-and-up '
            style={{ height: ".75em", width: ".75em", marginTop: "4%" }}
          />

          <a href='#!' data-target='mobile-demo' className='sidenav-trigger'>
            <i className='material-icons'>menu</i>
          </a>
          <ul className='right hide-on-med-and-down'>
            <li>
              <a href='#Memes'>Memes</a>
            </li>
            {/* <li>
              <a href='#Careers'>Careers</a>
            </li> */}
          </ul>
        </div>
      </nav>

      <ul className='sidenav' id='mobile-demo'>
        <li>
          <a href='#Memes'>Memes</a>
        </li>
        {/* <li>
          <a href='#Careers'>Careers</a>
        </li> */}
      </ul>
    </Fragment>
  );
};

export default Navbar;
